import gql from "graphql-tag";
import PriceRateCancelConditionGroupFragmentGql from "../Group/fragment/PriceRateCancelConditionGroupFragment.gql";

export default gql`

 ${PriceRateCancelConditionGroupFragmentGql}

 fragment PriceRateCancelConditionFragment on PriceRateCancelCondition {
  id
  label
  hotelId
  PriceRateCancelConditionGroup {
    ...PriceRateCancelConditionGroupFragment
  }
}
`