import { useQuery } from "@apollo/react-hooks"
import { IconInfo, IconWarningSign, NumberInput, SelectInput, TextField, TextInput } from "@zipou/front_tools"
import seasonalityListGql from "graphql/PMS/Seasonality/query/seasonalityList.gql"
import { PriceRateCancelConditionGroupInput, PriceRateCancelConditionItemValueEnum, Seasonality } from "model"
import React from "react"
import { PriceRateCancelConditionItemValueDisplay } from "../../Item/PriceRateCancelConditionItemValueDisplay"

type PriceRateCancelConditionGroupFormProps = {
  priceRateCancelConditionGroup: PriceRateCancelConditionGroupInput,
  onChange: (priceRateCancelConditionGroup: PriceRateCancelConditionGroupInput) => void,
  errors: any,
  hotelId: string,
}

export const PriceRateCancelConditionGroupForm = ({ priceRateCancelConditionGroup, onChange, errors, hotelId }: PriceRateCancelConditionGroupFormProps) => {

  const { data } = useQuery<{ list: Seasonality[] }>(seasonalityListGql, {
    variables: {
      hotelId,
    }
  })

  const seasonalityList = data?.list
  const conditionList = priceRateCancelConditionGroup?.conditionList
  const valueList = Object.values(PriceRateCancelConditionItemValueEnum)

  return <div>

    <div className="input-group">
      <span className="input-group-text">Saisonalité</span>
      <select className="form-control" value={priceRateCancelConditionGroup?.seasonalityId || ""} onChange={(e) => {
        onChange({
          ...priceRateCancelConditionGroup,
          seasonalityId: e.target.value,
        })
      }}>
        <option value="">Toutes</option>
        {seasonalityList?.map(s => {
          return <option key={`season_${s.id}`} value={s?.id}>{s.name}</option>
        })}
      </select>
    </div>


    <div className="alert alert-warning mt-3">Les libellés ci-dessous sont visible par le client dans les Propositions et Confirmations</div>
    <TextField isError={!!errors?.labelFr}  label="Libellé FR" value={priceRateCancelConditionGroup?.labelFr || ""} onChange={(value) => {
      onChange({
        ...priceRateCancelConditionGroup,
        labelFr: value,
      })
    }} />
    <TextField isError={!!errors?.labelEn} label="Libellé EN" value={priceRateCancelConditionGroup?.labelEn || ""} onChange={(value) => {
      onChange({
        ...priceRateCancelConditionGroup,
        labelEn: value,
      })

    }} />


    {/* <SelectInput id="defaultValue" choiceList={valueList.map((value: string) => ({ id: value, label: value }))} errors={errors} value={priceRateCancelConditionGroup?.defaultValue || ""} label="Valeur par Defaut" onChange={(value) => {
      onChange({
        ...priceRateCancelConditionGroup,
        defaultValue: value as PriceRateCancelConditionItemValueEnum,
      })
    }} /> */}



    <div className="input-group">
      <span className="input-group-text">Conditions</span>
      <div className="form-control" style={{ height: "100%" }}>


        <div className="input-group">
          <span className="input-group-text">Valeur par défaut</span>
          <select className="form-control" value={priceRateCancelConditionGroup?.defaultValue || ""} onChange={e => {
            onChange({
              ...priceRateCancelConditionGroup,
              defaultValue: e.target.value as PriceRateCancelConditionItemValueEnum,
            })
          }}>
            <option value="">Choisissez une valeur</option>
            {valueList.map(p => {
              return <option key={`value_${p}`} value={p}>
                {PriceRateCancelConditionItemValueDisplay({ value: p })}
              </option>
            })}
          </select>
        </div>



        <div className="alert alert-info mt-2"><IconInfo /><span className="ml-1">Jusqu'à X jours avant l'arrivée, appliquez la condition Y, sinon appliquez la condition par défaut.</span></div>

        {conditionList?.map((condition, index: number) => {

          // const isOnError = errors && errors?.conditionList && errors?.conditionList[index]
          const fieldError = errors && errors?.conditionList && errors?.conditionList[index]

          return <div key={`condition_${index}`} className="" style={{ height: "100%" }}>
            <div className="input-group">
              <div className="form-control" style={{ height: "100%" }}>
                <NumberInput id="before" value={`${condition.before || ""}`} label="Jours avant arrivée" errors={fieldError} onChange={(_, v) => {
                  onChange({
                    ...priceRateCancelConditionGroup,
                    conditionList: priceRateCancelConditionGroup?.conditionList?.map((cond: any, idx: number) => idx === index ? {
                      ...cond,
                      before: v,
                    } : cond)
                  })
                }} />
                {/* <SelectInput choiceList={valueList?.map(el => ({ id: el, label: el }))} value={condition.value} label="Condition" errors={fieldError} onChange={(value) => {
                  onChange({
                    ...priceRateCancelConditionGroup,
                    conditionList: priceRateCancelConditionGroup?.conditionList?.map((cond: any, idx: number) => idx === index ? {
                      ...cond,
                      value
                    } : cond)
                  })
                }} /> */}
                <div className="input-group">
                  <span className="input-group-text">Condition</span>
                  <select className="form-control" value={condition.value || ""} onChange={e => {
                    onChange({
                      ...priceRateCancelConditionGroup,
                      conditionList: priceRateCancelConditionGroup?.conditionList?.map((cond: any, idx: number) => idx === index ? {
                        ...cond,
                        value: e.target.value,
                      } : cond)
                    })
                  }}>
                    <option value="">Choisissez une valeur</option>
                    {valueList.map(p => {
                      return <option key={`value_${p}`} value={p}>
                        {PriceRateCancelConditionItemValueDisplay({ value: p })}
                      </option>
                    })}
                  </select>
                </div>
              </div>
              <span className="input-group-text" onClick={() => {
                onChange({
                  ...priceRateCancelConditionGroup,
                  conditionList: priceRateCancelConditionGroup?.conditionList?.filter((el: any, idx: number) => idx !== index)
                })
              }}>
                <span className="icon-trash" />
              </span>
              {fieldError && <span className="input-group-text">
                <IconWarningSign />
              </span>}

            </div>
          </div>
        })}
        <button className="btn btn-dark btn-sm" onClick={() => {
          onChange({
            ...priceRateCancelConditionGroup,
            conditionList: [
              ...(priceRateCancelConditionGroup?.conditionList || []),
              {
                before: null,
                value: null,
              }
            ]
          })
        }}>Ajouter une condition</button>
      </div>
      {errors && errors.conditionList && <span className="input-group-text">
        <IconWarningSign />
      </span>}
    </div>
  </div >

}