import gql from "graphql-tag";

export default gql`

fragment PriceRateCancelConditionGroupFragment on PriceRateCancelConditionGroup {
  defaultValue
  seasonalityId
  labelFr
  labelEn
  Seasonality {
    id
    name
  }
  conditionList {
    before
    value
  }
}

`