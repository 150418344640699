
import { PriceRateCancelConditionInput, PriceRateCancelConditionItemValueEnum } from "model";
import React, { useState } from "react"

import "./style.scss"
import { IconEdit, IconTrash, IconWarningSign, Modal, NumberInput, SelectInput, TextField, TextInput } from "@zipou/front_tools";
import { PriceRateCancelConditionGroupForm } from "../Group/Form/PriceRateCancelConditionGroupForm";
import { SeasonalityLabel } from "components/front/PMS/Seasonality/Label/SeasonalityLabel";

type PriceRateCancelConditionFormProps = {
  priceRateCancelCondition: PriceRateCancelConditionInput,
  errors: any,
  onChange: (data: PriceRateCancelConditionInput) => void,
  hotelId: string,
}


export const PriceRateCancelConditionForm = ({ priceRateCancelCondition, errors, onChange, hotelId }: PriceRateCancelConditionFormProps) => {

  const [focusIndex, updateFocusIndex] = useState<number | null>()

  // const valueList = Object.values(PriceRateCancelConditionItemValueEnum).map((value: string) => ({ id: value, label: value }))

  const groupList = priceRateCancelCondition?.PriceRateCancelConditionGroup

  return <div className="cancel-condition-container">
    <Modal display={groupList?.length > 0 && !!groupList[focusIndex]} title="Editer" onClose={() => updateFocusIndex(null)}>
      <PriceRateCancelConditionGroupForm hotelId={hotelId} priceRateCancelConditionGroup={groupList?.find((_, i) => i === focusIndex)} errors={errors && errors.PriceRateCancelConditionGroup} onChange={prccg => {
        onChange({
          ...priceRateCancelCondition,
          PriceRateCancelConditionGroup: groupList?.map((el, i) => i === focusIndex ? prccg : el),
        })
      }} />

    </Modal>
    <div>
      <TextInput id="label" errors={errors} value={priceRateCancelCondition?.label || ""} label="Nom" onChange={(value) => {
        onChange({
          ...priceRateCancelCondition,
          label: value,
        })
      }} />

      <div className="card mt-2">
        <div className="card-header bg-dark text-white">Conditions</div>
        <div className="card-body">
          <table className="table table-bordered table-stripped">
            <thead>
              <tr className="text-white bg-secondary">
                <th className="text-white bg-secondary">Saisonalité</th>
                <th className="text-white bg-secondary">Actions</th>
              </tr>
            </thead>
            <tbody>
              {groupList?.map((group, index) => {
                return <tr key={`group_${group?.id}`}>
                  <td>
                    {group.seasonalityId && <SeasonalityLabel id={group.seasonalityId} />}
                    {!group.seasonalityId && "Toutes"}
                  </td>
                  <td>
                    <button className="btn btn-warning btn-sm ml-1" onClick={() => updateFocusIndex(index)}>
                      <IconEdit />
                    </button>

                    <button className="btn btn-danger btn-sm ml-1" onClick={() => {
                      onChange({
                        ...priceRateCancelCondition,
                        PriceRateCancelConditionGroup: groupList?.filter((el, idx) => idx !== index),
                      })
                    }}>
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              })}
            </tbody>
          </table>

        </div>
        <div className="card-footer">
          <button className="btn btn-dark btn-sm" onClick={() => {
            updateFocusIndex(groupList?.length)
            onChange({
              ...priceRateCancelCondition,
              PriceRateCancelConditionGroup: [
                ...(groupList || []),
                {

                }
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>

    </div>
  </div>
}