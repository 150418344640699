import gql from "graphql-tag";
import PriceRateTypeFragmentGql from "../fragment/PriceRateTypeFragment.gql";

export default gql`

${PriceRateTypeFragmentGql}

  query priceRateTypeList($hotelId: String!) {
    list: priceRateTypeList(hotelId: $hotelId) {
      ...PriceRateTypeFragment
    }
  }

`