import gql from "graphql-tag";

export default gql`

fragment SegmentationFragment on Segmentation {
  id
  name
  status
  displayCustomerDashboard
  picto
  color
  lastError
  lastSyncDate
  progress
  options {
    stay {
      statusList
      type
      when
      where
      dateStart
      dateEnd
      nbNight {
        type
        value
      }
      nbReservations {
        type
        value
      }
      hotelList
      nbAdults
      nbChildren
      hasSpa
    }
    origin {
      type
      hotelList
    }
    customer {
      type
    }
    contact {
      gender
      country
      zipCode
    }
  }
}
`