import { PriceRateCancelConditionInput } from "model"
import React from "react"
import { PriceRateCancelConditionForm } from "../Form/PriceRateCancelConditionForm"

type PriceRateCancelConditionEditProps = {
  priceRateCancelCondition: PriceRateCancelConditionInput,
  onChange: (priceRateCancelCondition: PriceRateCancelConditionInput) => void,
  errors: any,
  hotelId: string,
}

export const PriceRateCancelConditionEdit = ({ priceRateCancelCondition, onChange, errors, hotelId, }: PriceRateCancelConditionEditProps) => {
  return <div>
    <PriceRateCancelConditionForm hotelId={hotelId} priceRateCancelCondition={priceRateCancelCondition} errors={errors} onChange={onChange} />
  </div>
}