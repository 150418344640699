import React, { useState } from "react"

import "./style.scss"


type TagInputProps = {
  label: string,
  id: string,
  minLength?: number,
  placeholder?: string,
  onChange: (v: any) => void,
  value: { id: string, label: string }[],
  choiceList?: { id: string, label: string }[],
}

export const TagInput = (props: TagInputProps) => {

  const [resultListIndexSelected, updateResultListIndexSelected] = useState(-1)
  const [textValue, updateTextValue] = useState("")

  const onDelete = (index: number) => {
    props?.onChange(props?.value?.filter((tag: any, idx: number) => idx !== index))
  }

  const onSelectValue = (value: any) => {
    updateTextValue("")
    props?.onChange([
      ...(props?.value || []),
      value,
    ])
  }



  const onChange = (value: string) => {
    updateTextValue(value)
  }


  const handleKeyDown = (e: any) => {
    const nbResults = resultList.length
    if (e.keyCode === 38 && (resultListIndexSelected > 0)) {
      //UP
      updateResultListIndexSelected(resultListIndexSelected - 1)
    } else {
      if (e.keyCode === 40 && (resultListIndexSelected < nbResults - 1)) {
        //DOWN
        updateResultListIndexSelected(resultListIndexSelected + 1)
      } else {
        if (e.keyCode === 13) {
          e.preventDefault()
          if (resultListIndexSelected > -1) {
            //Selection is from list
            onSelectValue(resultList[resultListIndexSelected])
          } else {
            //Selection is from Vzlue types
            onSelectValue({ id: textValue, label: textValue })
          }
        }
      }
    }
  }

  const resultList = (textValue.length >= (props?.minLength || 2) && props?.choiceList
    ?.sort((el1: any, el2: any) => el1?.label.localeCompare(el2?.label))
    ?.filter((tags: { id: string, label: string }) => tags?.label?.toLowerCase().includes(textValue.toLowerCase()))
    ?.filter((tags: { id: string, label: string }) => !props?.value?.find((el: any) => el?.id === tags?.id)))
    || []
  const hasResults = resultList?.length > 0

  return <div className="input-group">
    <div className="input-group-text">{props?.label}</div>
    <div style={{ flexGrow: 1 }}>
      <div className="form-control" style={{ padding: 0, paddingLeft: 10, display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        {props?.value?.map((tag: any, index: number) => {
          return <span className="badge badge-sm badge-secondary" style={{ marginLeft: 3 }}>{tag.label} <span className="icon-close" onClick={() => onDelete(index)} /></span>
        })}
        <input className="input-tag" onKeyDown={handleKeyDown} type="text" placeholder={props?.placeholder || ""} style={{ border: "0px black solid", height: 30, marginLeft: 3, flexGrow: 1 }} value={textValue} onChange={(e: any) => onChange(e.target.value)} />
      </div>
      <div className="results">
        <ul className="list-group" style={{ marginTop: -5, paddingRight: 5, paddingLeft: 5, position: "absolute", zIndex: 10000 }}>
          {hasResults && resultList?.map((result: any, index: number) => {
            const isActive = index === resultListIndexSelected
            return <li onMouseEnter={() => updateResultListIndexSelected(index)} onMouseLeave={() => updateResultListIndexSelected(-1)} className={isActive ? "list-group-item active" : "list-group-item"} onClick={() => onSelectValue(result)}>{result.label} </li>
          })}
        </ul>
      </div>
    </div>
  </div>
}