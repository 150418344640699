import { useQuery } from "@apollo/react-hooks"
import seasonalityGetGql from "graphql/PMS/Seasonality/query/seasonalityGet.gql"
import seasonalityListGql from "graphql/PMS/Seasonality/query/seasonalityList.gql"
import { Seasonality } from "model"
import React from "react"

type SeasonalityLabelProps = {
  id: string,
}

export const SeasonalityLabel = ({ id }: SeasonalityLabelProps) => {

  const { data } = useQuery<{ seasonality: Seasonality }>(seasonalityGetGql, {
    variables: {
      id,
    }
  })

  const seasonality = data?.seasonality

  return <span className="">{seasonality?.name || id}</span>


}
