import { PriceRateCancelConditionItemValueEnum } from "model"
import React from "react"

type PriceRateCancelConditionItemValueDisplayProps = {
  value: PriceRateCancelConditionItemValueEnum
}

export const PriceRateCancelConditionItemValueDisplay = ({ value }: PriceRateCancelConditionItemValueDisplayProps) => {
  switch (value) {

    case PriceRateCancelConditionItemValueEnum.CONDITION_0:
      return "Aucun Frais"
    case PriceRateCancelConditionItemValueEnum.CONDITION_10:
      return "10% de la résa"
    case PriceRateCancelConditionItemValueEnum.CONDITION_15:
      return "15% de la résa"
    case PriceRateCancelConditionItemValueEnum.CONDITION_20:
      return "20% de la résa"
    case PriceRateCancelConditionItemValueEnum.CONDITION_30:
      return "30% de la résa"
    case PriceRateCancelConditionItemValueEnum.CONDITION_50:
      return "50% de la résa"
    case PriceRateCancelConditionItemValueEnum.CONDITION_100:
      return "Totalité de la résa"

  }
}