import gql from "graphql-tag";
import SeasonalityFragment from "../fragment/SeasonalityFragment";

export default gql`

${SeasonalityFragment}

query seasonalityGet($id: String!) {
  seasonality: seasonalityGet(id: $id) {
    ...SeasonalityFragment
  }
}
`